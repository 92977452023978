import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { PublicInformation } from 'helpers/queriesDataKeys';

import classes from './SideFooter.module.css';
import { API_BASE_URL } from 'configs/AppConfig';

const currentYear = new Date().getFullYear();

const SideFooter = () => {
  const {
    isLoading,
    isError,
    data: information,
    error
  } = useQuery([PublicInformation], async () => {
    const response = await fetch(`${API_BASE_URL}information/get/all`);

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }

    return data;
  });

  return (
    <div className={classes.sideFooter}>
      <section className={classes.section1}>
        {information?.data.map((info) => (
          <Link key={info._id} to={`/information/${info._id}`}>
            {info.name}
          </Link>
        ))}
        <p>© {currentYear} All rights reserved</p>
      </section>
      <section className={classes.section2}>
        {/* <p>English</p>
        <p>INR</p> */}
      </section>
    </div>
  );
};

export default SideFooter;
